import { Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Meta from 'components/Meta'
import Layout from 'components/Layout'
import SideProfile from 'components/SideProfile'

const About = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <Meta
        site={get(data, 'site.meta')}
        title={get(data, 'site.meta.title')}
        description={get(data, 'site.meta.about')}
        image={'/images/reef-dsouza-blog-cover-photo.jpg'}
        path={'/about/'}
      />

      <div className="kotha-default-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <article className="single-blog contact-us">
                <div className="post-thumb">
                  <img src="/images/reef-dsouza-blog-cover-photo.jpg" alt="" />
                </div>
                <div className="post-content">
                  <div className="entry-header text-center text-uppercase">
                    <h2 className="text-left">
                      <a href="">{data.site.meta.title}</a>
                    </h2>
                  </div>
                  <div className="entry-content">
                    <p>
                      I moved to the U.S. in 2008 at the start of the Great
                      Recession. As an immigrant it’s rough hearing the first
                      things from the news when you land, that the country is
                      officially entering a period of financial disaster and a
                      period of uncertain economic times. Now, 10 years since,
                      we see the aftershocks of that earthquake. The news is
                      dominated by anti-immigrant policy changes and inhumane
                      treatment of migrant children.
                    </p>
                    <p>
                      But in those 10 years, I lived in large multi-ethnic
                      melting pots in New York and Los Angeles. These included
                      Bay Ridge, Brooklyn with the old Irish and Italian
                      residents to the middle eastern population from Yemen,
                      Syria, Palestine, Turkey, Kensington, Brooklyn with
                      Bangladeshis and Hasidic Jews on the one side and
                      Jamaicans, Trinidadians, Guyanese, Haitians on the other
                      side along Flatbush Ave. Los Angeles’ Koreatown is
                      fascinating in its own right with a blend of Koreans,
                      Thai, Vietnamese, Bangladeshi, Mexican, El Salvadorean
                      among the many here.
                    </p>
                    <p>
                      All these interactions with these people and my own
                      experience of jumping through the insanely illogical
                      bureaucratic hoops to citizenship made me want to
                      understand their impact on society by means of food, music
                      or choice of profession and how American society and
                      culture might have rubbed off on them or their kids.
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-sm-4">
              <div className="kotha-sidebar">
                <SideProfile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        author
        siteUrl
        about
      }
    }
  }
`
